import React from 'react'
import './smallcard.css';
const SmallCard = (props) => {
    return (
        <div className="menuDiv" id="top" >
         <img style={ {backgroundImage: `url(${props.image})`}  } alt="" />
            <h3 className="menuTitle">{ props.heading}</h3>
        </div>
         
        
    )
}

export default SmallCard;
