import React, { Component } from "react";
import SideMenu from "../SideMenu/SideMenu";
import Logo from "../Logo/Logo";
import "./Header.scss";
import { ORDER_NOW } from '../../config/constants';
export default class Header extends Component {
  render() {
    return (
      <div className="header">
        <div className="header-menu">
          <SideMenu />
        </div>
        <Logo logoStyle="header-logo d-flex align-items-center justify-content-center"/>
        <div className="header-button d-flex align-items-justify justify-content-center">
          <a href={ ORDER_NOW } target="_blank" rel="noopener noreferrer" className="header-button btn btn-default">Order now</a>
        </div>
      </div>
    );
  }
}
