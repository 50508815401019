import React from "react";
import { NavLink } from "react-router-dom";
import "./findloct.css";

function Findloct() {
  return (
    <div className="nearLoct">
      {/*<a href="/locations" className="nearLoctP">find our nearest location</a>*/}
      <NavLink className="nearLoctP" exact to="/locations">
        find our nearest location
      </NavLink>
    </div>
  );
}

export default Findloct;
