import React from 'react';
import "./footertcon.css";

function Footertcon () {
    return (
        <h6 className="textFoot">
            Follow or contact us
        </h6>
    );
}

export default Footertcon;