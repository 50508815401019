import React from 'react';
import "./ButtonLink.css";

function ButtonLink (props) {
    return (
        <div className="nearLoct">
            <a href={ props.link } className="nearLoctP btnText" target={props.targetLink}>{ props.text }</a>
        </div>
    );
}

export default ButtonLink;