import React, { Component } from "react";
import "./Careers.scss";
import Careercard from "../../components/Careercard/Careercard";
import axios from 'axios';
import { BASE_URL, CAREER_ID } from '../../config/constants';
import Loader from "../../components/Loader/Loader";
import MetaTags from 'react-meta-tags';
import ErrorMsg from '../../components/ErrorMsg/ErrorMsg';


export default class Careers extends Component {
  constructor(props) {
    super(props);
    this.state = {
        career_data : [],
       
        isCareerLoaded: false,
        title:'',
        site_title:'',
        site_description:'',
        hasError: false,
        button:[],
        header_image:'',
      
    }
    
  }
  componentDidMount(){
   
    axios.get(BASE_URL+CAREER_ID)
    .then((res) => {
      this.setState({
        header_image:res.data.metadata['Header Image'][0],
        title: res.data.seo.site_title,
        site_title: res.data.metadata._yoast_wpseo_title?res.data.metadata._yoast_wpseo_title:'Careers',
        site_description: res.data.metadata._yoast_wpseo_metadesc,
        career_data:res.data,
        isCareerLoaded: true
      })
      
    }) 
    .catch(err => 
      this.setState({
        career_data: err,
        hasError: true,
        isCareerLoaded: true
      })
    );

    

  }
  render() {
    const { hasError, header_image, isCareerLoaded, career_data, site_title, title, site_description } = this.state;
    
    if(hasError)
    {
      return <ErrorMsg />;
    }else{
      if(isCareerLoaded)
      {
        return (
          <>
            <MetaTags>
              {site_title?
              <title>{` ${site_title} | ${title} `}</title>
              :
              <title>{`${title}`}</title>
              }
              <meta name="description" content={site_description} />
             
            </MetaTags>
            <Careercard career_data={career_data} header_image={header_image} />
          </>
        );

      }else{
        return <Loader />
      }
    }
  }
}
