import React from "react";
import "./footerbcon.css";

function Footerbcon() {
  return (
    <h6 className="copyRights">
      © {new Date().getFullYear()} | Dream of Falafel. All rights reserved.
      Designed & Developed by{" "}
      <a
        href="https://www.viabletree.com/"
        target="_blank"
        rel="noreferrer"
        style={{ color: "#0f6e5e", fontWeight: "bold" }}
      >
        Viabletree
      </a>
    </h6>
  );
}

export default Footerbcon;
