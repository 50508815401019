import React from "react";
import { Link } from "react-router-dom";
import "./card.css";
import { ROOT_URL, PAGES } from "../../config/constants";
import Loader from "../../components/Loader/Loader";
const _ = require("lodash");

// function Card(props) {
//   const { title, text, section, background_image } = props.item;
//   return (
//     <div className="mainDiv">
//       <img style={{ backgroundImage: `url(${background_image})` }} alt="" />
//       <h3 className="mainTitle">{title}</h3>
//       <p className="mainPara">{text}</p>
//       <Link className="viewLink" to={`/${section.toLowerCase()}`}>
//         {section}
//         <i className={`fa fa-arrow-right`}></i>
//       </Link>
//     </div>
//   );
// }

function Card(props) {
  const { title, text, section, background_image } = props.item;
  const page_link = _.isUndefined(PAGES[section.toLowerCase()])
    ? "#"
    : PAGES[section.toLowerCase()];
  return (
    <div className="mainDiv">
      <img style={{ backgroundImage: `url(${background_image})` }} alt="" />
      {/* <Img src={[background_image]} alt={title} loader={Loader} /> */}
      <h3 className="mainTitle">{title}</h3>
      <p className="mainPara">{text}</p>
      <Link className="viewLink" to={`${ROOT_URL}/${page_link}`}>
        {section}
        <i className={`fa fa-arrow-right`}></i>
      </Link>
    </div>
  );
}

export default Card;
