import React, { Component } from "react";
import "./Home.scss";
import Card from '../../components/Card/Card';
import axios from 'axios';
import Findloct from "../../components/Findloct/Findloct";
import { BASE_URL, HOME_ID } from '../../config/constants';
import Loader from "../../components/Loader/Loader";
import MetaTags from 'react-meta-tags';
import ErrorMsg from '../../components/ErrorMsg/ErrorMsg';
// import { getHome } from '../../api';
// import { create } from 'apisauce';


export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
        item : [],
        title : '',
        site_title : '',
        site_description : '',
        isLoaded: false,
        hasError: false
    }
    
  }
  componentDidMount(){
    axios.get(BASE_URL+HOME_ID)
    .then(res => {
      console.log(res.data.metadata);
      this.setState({
        item: res.data.home_page,
        title: res.data.seo.site_title,
        site_title: res.data.metadata._yoast_wpseo_title?res.data.metadata._yoast_wpseo_title:'Home',
        site_description: res.data.metadata._yoast_wpseo_metadesc,
        isLoaded: true
      })
      
    })
    .catch(err => 
      this.setState({
        item: err,
        isLoaded: true,
        hasError: true
      })
    );


  }

  render() {
    const { item, isLoaded, hasError, site_title, site_description, title } = this.state;
   if(isLoaded)
   {
     if(hasError)
     {
      return <ErrorMsg />;
     }else{
       return (
   
         <div id="home">
            <MetaTags>
              {site_title?
              <title>{` ${site_title} | ${title} `}</title>
              :
              <title>{`${title}`}</title>
              }
              <meta name="description" content={site_description} />
             
            </MetaTags>
           {isLoaded?<Card key="0" item={ item[0] } />:''}
             <Findloct />
             {          
                 item.map((i, index) => {
                     if(index > 0){
                       return(<Card key={index} item={ i }/>  )
                     } 
                     return '';
                 })        
             }
         </div>
       );

     }

   }else{
     return <Loader />
   }
  }
}