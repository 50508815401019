import React, { Component } from 'react';
import "./slidedown.css";

class Slidedown extends Component{
    
    render(){
        return (
            <div  className="dropDownBox" id={this.props.id} onClick={ this.props.handleOpenSection}>
                  <div className="createDiv">
                      <div>{this.props.title} 
                      {(typeof this.props.isOpen == "undefined")?null:<i className={ this.props.isOpen?"fa fa-angle-down":"fa fa-angle-right"}></i>}
                      </div>
                 </div>
    
            </div>
        )

    }


}


export default Slidedown;