import React, { Component } from "react";
import "./locationcard.css";
import Slidedown from "../SlideDown/SlideDown";
import LocDetails from "../LocDetails/LocationDetails";
import LocAddress from "../LocAddress/LocAddress";
import SmallCard from "../SmallCard/SmallCard";
import RenderMap from "../RenderMap/RenderMap";
import ButtonLink from "../ButtonLink/ButtonLink";
import { ORDER_NOW } from "../../config/constants";

class LocationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSection: ""
    };
  }
  openSection = title => {
    this.setState((prevState, props) => {
      if (prevState.openSection === title) {
        return { openSection: "" };
      } else {
        return { openSection: title };
      }
    });
  };
  render() {
    const { item } = this.props;
    const header_image = item.location_page["Header Image"][0];
    // const header_image_mobile = typeof item.metadata["header_image_mobile"] != "undefined"?item.metadata["header_image_mobile"][0]:'';
    const { openSection } = this.state;
    const LIST = "List";
    const MAP = "Map";
    return (
      <div>
        <SmallCard
          heading="Locations"
          image={/*header_image_mobile*/ header_image}
        />

        <div className="detailsListMain">
          <Slidedown
            title={LIST}
            isOpen={this.state.openSection === LIST}
            handleOpenSection={() => this.openSection(LIST)}
          />
          <div
            className="detailsList"
            title={LIST}
            style={openSection === LIST ? null : { display: "none" }}
          >
            <div className="createDiv1"></div>
            {item.location_page.child.map(value => {
              return (
                <div className="border-location">
                  <LocDetails title={value.title} />
                  {value.location.map(value => (
                    <LocAddress key={value.location_telephone} item={value} />
                  ))}
                </div>
              );
            })}
          </div>
        </div>

        <div className="detailsListMain">
          {/* <Slidedown title="Map" /> */}
          <Slidedown
            title={MAP}
            isOpen={this.state.openSection === MAP}
            handleOpenSection={() => this.openSection(MAP)}
          />
          <div
            className="detailsList2"
            title={MAP}
            style={openSection === MAP ? null : { display: "none" }}
          >
            <div className="createDiv1"></div>
            <div
              style={{ position: "relative", height: "400px", width: "100%" }}
            >
              <RenderMap
                zoom={7 /*item.location_page.map_info.zoom*/}
                center={item.location_page.map_info.center}
                locations={item.location_page.coordinates}
              />
            </div>
            {/* <div className="mapStyling"> <RenderMap zoom={item.location_page.map_info.zoom} center={item.location_page.map_info.center} locations={item.location_page.coordinates} /> </div> */}
          </div>
        </div>

        <ButtonLink link={ORDER_NOW} text="ORDER NOW" targetLink="_blank" />
      </div>
    );
  }
}

export default LocationCard;
