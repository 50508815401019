import React from 'react';
import './careercard.css';
import Careertext from '../CareerText/Careertext';
import LocDetails from '../LocDetails/LocationDetails';
import CareerHead from '../CareerHead/CareerHead';
import Slidedown from '../SlideDown/SlideDown';
import RenderHTML from '../RenderHTML/RenderHTML';

function Careercard (props) {

    return (
        <div>
            <div className="careerDiv">
               <h3 className="careerTitle">Careers</h3>
            </div>

            <div className="caretext">
               <Careertext text="Applications may not be submitted via the mobile site. Please visit this page in desktop mode to apply." />
            </div>

                <Slidedown title="Available Positions"/>
               <div className="detailsListMain">
                    {props.career_data.career_page.map(value => { return(
                         
                    <div className="detailsList1" key={value.description}>
                         <div className="createDiv1">
                         
                         </div>
                         <LocDetails title={value.title} />

                         <RenderHTML item={ value.content} />
                         {(value.description)?
                         <div className="careDiv">
                              <CareerHead text="Job Description" />
                              <Careertext text={ value.description} />
                         </div>
                         :''}
                         {(value.essentails)?
                         <div className="careDiv">
                              <CareerHead text="Essential Skills" />
                              <RenderHTML item={value.essentails} />
                         </div>
                         :''}                         
                         {(value.notes)?
                              <div className="careDiv">
                                   <CareerHead text="Nice to haves" />
                                   <Careertext text={ value.notes} />
                              </div>
                         :''}
                         {(value.location)?
                              <div className="careDiv">
                                   <CareerHead text="Location" />
                                   <Careertext text={ `${value.location} | ${value.time}`} />
                              </div>
                         :''}
                         

                    </div>
                    )})}
               </div>
        </div>
    )
}

export default Careercard;