import React from 'react';
import './careertext.css';

function Careertext (props) {
    return (
        <div>
            <p className="careerPara">
            {props.text}
            </p>
        </div>
    )
}

export default Careertext;