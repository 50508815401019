import React from "react";
import "./footer.css";
import Footertcon from "../Footertcon/Footertcon";
import Footerbcon from "../Footerbcon/Footerbcon";
import Socialicons from "../Socialicons/Socialicons";
import { SOCIAL } from "../../config/constants";

function Footer() {
  var scrollTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <footer>
      <Footertcon />
      <div className="siList">
        <ul>
          <li>
            <a target="_blank" rel="noopener noreferrer" href={SOCIAL.FACEBOOK}>
              <Socialicons icon="facebook" />
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href={SOCIAL.INSTA}>
              <Socialicons icon="instagram" />
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href={SOCIAL.TWITTER}>
              <Socialicons icon="twitter" />
            </a>
          </li>
          {SOCIAL.EMAIL ? (
            <li>
              <a target="_blank" rel="noopener noreferrer" href={SOCIAL.EMAIL}>
                <Socialicons icon="envelope" />
              </a>
            </li>
          ) : (
            ""
          )}
          <li>
            <a onClick={() => scrollTop()}>
              <Socialicons icon="arrow-up" />
            </a>
          </li>
        </ul>
      </div>
      <Footerbcon />
    </footer>
  );
}

export default Footer;
