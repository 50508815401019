import React from 'react';
import './careerhead.css';

function Careerhead (props) {
    return (
        <div>
            <h5 className="careerHeading">
                {props.text}
            </h5>
        </div>
    )
}

export default Careerhead;