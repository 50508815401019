import React from "react";
import RecipeCard from "../RecipeCard/RecipeCard";

const RecipeCardWrapper = props => {
  const { item, extra_class } = props;

  return (
    <div className="iconStyleHead">
      {item
        ? item.map((value, index) => {
            return (
              <RecipeCard
                key={value.title + value.price}
                image={value.image}
                item_title={value.title}
                item_description={value.description}
                item_options={value.options}
                item_price={value.price}
                extra_class={extra_class}
              />
            );
          })
        : ""}
    </div>
  );
};

export default RecipeCardWrapper;
